import Collection from "~/packages/core/models/Collection";
import AuditModel from "~/packages/core/models/tools/AuditModel";

class AuditsCollection extends Collection {
    /**
     * Define associated model to the CaptionsCollection
     */
    override model(): typeof AuditModel {
        return AuditModel;
    }
}

export default AuditsCollection;