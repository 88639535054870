import Model from "~/packages/core/models/Model";

class FeaturedMenuItemModel extends Model {
  override casts(): {} {
    return {
      id: "uid",
      group_id: "number",
      name: "string",
      url: "string",
      ordering: "number",
    };
  }
}

export default FeaturedMenuItemModel;