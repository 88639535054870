/*
 * useAssets extends defaultApi composable to use Api state and Common Api Notifications
 */

import type { Payload } from "~/packages/core/types/Api";
import BaseModel from "~/packages/core/models/BaseModel";
import { useGlobalState } from "~/store/useGlobalState";

class useAssets extends useDefaultApi {
    protected override api: string = "assets";
    protected override excluded: string[] = [
        "authors",
        "premium",
        "prequal",
        "interviewee_list",
        "related",
        "vendor_directory",
    ];

    getAssetTypeForRouting(assetType: string) {
        if(assetType === 'podcast'){
            return 'interview';
        }
        if(assetType === 'blogpost'){
            return 'blogs';
        }
        return assetType;
    }

    async getSearchedAsset(type: string, query: string, payload: Payload) {
        if (query) {
            return await useApi()[this.api].searchAssets(type, query, payload);
        }
    }

    override async get(id: string | number, options?: { payload?: Payload }, url?: string) {
        return super.get(
            id,
            {
                payload: options?.payload || {
                    with: [
                        "authors",
                        "prequal",
                        "vendorDirectory",
                        "premium",
                        "related",
                        "categories",
                    ],
                },
            },
            url
        );
    }

    override async create(model: BaseModel, payload?: Payload, url?: string, goBack = false) {
        const result = await super.create(model, payload, url, false, async (entity) => {
            if (entity?.asset_id) {
                model.id = entity.asset_id
                if(model.isPremiumExclusiveAsset){
                    model.premium.content_type = entity.asset_type;
                    model.premium.content_id = entity.asset_id;
                    await this.handleRelation("premium", model, payload, url)
                }
            }
            if (model) {
                await this.handleRelation("authors", model, payload, url)
            }
        })

        // Show modal after successful creation
        if (result?.metadata?.status === "200") {
            const store = useStoreGlobalState()
            let basePath = ''
            try {
                const route = useRoute()
                if (route?.path) {
                    basePath = route.path.split('/').slice(0, 2).join('/')
                }
            } catch (e) {
                console.warn('Route not available, using default path')
            }
            
            store.globalState.showNavigationModal = true
            store.globalState.navigationAssetInfo = {
                model: result,
                listing_url: `${basePath}/${this.getAssetTypeForRouting(result.asset_type)}`,
                edit_url: `${basePath}/${this.getAssetTypeForRouting(result.asset_type)}/${result.asset_id}`
            }
        }

        return result
    }

    override async update(model: BaseModel, payload?: Payload, url?: string) {
        model.id = model.asset_id;
        return super.update(model, payload, url, async () => {
            if (model) {
                return await this.handleRelation("authors", model, payload, url);
            }
        });
    }

    override async handleRelation(relation: string, model: BaseModel, payload?: Payload, url?: string) {
        useGlobalState().isApiLoading = true;
        // if no model relation stop method here
        if(!model[relation]) {
          return;
        }

        try {
            const entity = await useApi()[this.api].handleRelation(relation, model, payload, url);
            useGlobalState().isApiLoading = false;
            return entity;
        } catch (error) {
            console.error('Error handling relation:', error);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }
}

export default useAssets;