/*
* useTranscoding extends defaultApi composable to use Api state and Common Api Notifications
 */

import {useGlobalState} from "~/store/useGlobalState";
import {type Payload} from "@nuxt/devtools-kit/types";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";

class useTranscoding extends useDefaultApi {
    protected api:string = 'transcoding'
    protected excluded:string[] = []

    /**
     * status list
     *
     **/
    async statusList(options?:{url?: string, payload?: Payload}) {
        if(options?.payload != undefined){
            options.payload = options.payload || { per_page: 25 };
        }
        //@ts-ignore
        return await useApi()[this.api].statusList(options?.payload, options?.url);
    }

    /**
     * transcode Master File
     **/
    async transcodeMasterFile(options:{filename:string}) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].transcodeMasterFile(options);
            NotifyByAPIResponse.execute(entity.value)
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }


    /**
     * deleteTranscoding
     **/
    async deleteTranscoding(options:{filename:string, container:string}) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].deleteTranscoding(options);
            NotifyByAPIResponse.execute(entity)
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }

    /**
     * renameTranscoding
     **/
    async renameTranscoding(options:{filename:string, container:string, new_name:string}) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].renameTranscoding(options);
            NotifyByAPIResponse.execute(entity.value)
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }
}

export default  useTranscoding;