import { type Payload } from "~/packages/core/types/Api";
import BaseModel from "~/packages/core/models/BaseModel";
import { useGlobalState } from "~/store/useGlobalState";
import GoBack from "~/packages/core/actions/utility/GoBack";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";
/**
 * useDefaultApiOld composable holds responsibility of calling correct Api, state and notifications
 * this will be common composable to use api's across the all the entities with common CRUD operations
 * if there are any extra customizations we will take useAsset composable approach
 * **/
class useDefaultApi {
  protected api: string = "";
  protected excluded: string[] = [];

  /**
   * list_all will list all data without limit used for select input
   **/
  async list_all(payload?: Payload, path: string ='') {
    const api_path = path + '/all';
    //@ts-ignore
    return await useApi()[this.api].list(payload, api_path);
  }

  /**
   * list will get a data list with limit ex: per_page=15
   *
   **/
  async list(options?:{url?: string, payload?: Payload}) {
    if(options?.payload != undefined){
      options.payload = options.payload || { per_page: 25 };
    }
    //@ts-ignore
    return await useApi()[this.api].list(options?.payload, options?.url);
  }

  /**
   * get will get the single entity based on id provided to it
   **/
  async get(id: any, options?:{ payload?: Payload }, url?: any, cast:boolean = true) {
    try {
      //@ts-ignore
      return await useApi()[this.api].get(id, options?.payload, url, cast);
    } catch (error) {
      useNotification().notify("error", `${error}`);
    }
  }

  /**
   * create will create a single entity by taking the required data as model
   **/
  async create(model: BaseModel, payload?:Payload, url?:string, goBack:boolean = true, callback?: (entity:BaseModel) => void) {
    useGlobalState().isApiLoading = true;
    try {
      //@ts-ignore
      const entity = await useApi()[this.api].create(
        model.getModelData(this.excluded), payload, url
      );

      if (entity.metadata?.status === "200") {
        // @ts-ignore
        NotifyByAPIResponse.execute(entity)

        // used to
        if (callback && typeof callback === 'function') {
          callback(entity);
        }

        if(goBack) {
          GoBack.execute();
        }
        
        return entity;
      }
    } catch (error) {
      //@ts-ignore
      useNotification().notify("error", `${error.message}`);
    } finally {
      useGlobalState().isApiLoading = false;
    }
  }

  /**
   * update will update a single entity by taking the required data as model and grabbing the id from model
   **/
  async update(model: BaseModel, payload?: Payload | {}, url?:string, callback?: (entity:BaseModel) => void) {
    useGlobalState().isApiLoading = true;
    try {
      //@ts-ignore
      const entity = await useApi()[this.api].update(
        model.getModelData(this.excluded),
        model.id,
        payload,
        url
      );

      if (entity?.metadata?.status === "200") {
        NotifyByAPIResponse.execute(entity)
      }

      if (callback && typeof callback === "function") {
        callback(entity);
      }

    } catch (error) {
      //@ts-ignore
      useNotification().notify("error", `${error.message}`);
    } finally {
      useGlobalState().isApiLoading = false;
    }
  }

  /**
   * handleRelation will POST a relation to single entity by taking the required data as model and grabbing the id from model
   **/
  async handleRelation(relation: any, model: any, payload?:Payload, url?: string) {
    useGlobalState().isApiLoading = true;

    // if no model relation stop method here
    if(!model[relation]) {
      return;
    }

    try {
      //@ts-ignore
      const entity = await useApi()[this.api].handleRelation(
        relation,
        model,
        payload,
        url,
      );
      if (entity) {
        if (entity.meta.status === "200") {
          NotifyByAPIResponse.execute(entity)
        }
      }
    } catch (error) {
      //@ts-ignore
      useNotification().notify("error", `${error.message}`);
    } finally {
      useGlobalState().isApiLoading = false;
    }
  }

  /**
   * deleteEntity will DELETE a single entity based on the id provided to it
   **/
  async deleteEntity(id: any, payload?:Payload, url?:any) {
    useGlobalState().isApiLoading = true;
    try {
      //@ts-ignore
      const entity = await useApi()[this.api].delete(id, payload, url);
      if (entity) {
        if (entity.meta.status === "200") {
          NotifyByAPIResponse.execute(entity)
        }
      }
    } catch (error) {
      //@ts-ignore
      useNotification().notify("error", `${error.message}`);
    }
    finally {
      useGlobalState().isApiLoading = false;
    }
  }

  async processApiResponse<T, E = any>(fn: () => Promise<{ data: { value: T }; error: E; status?: number; [key: string]: any }>): Promise<{ data: { value: T }; error: E; status?: number; [key: string]: any } | undefined> {
    useGlobalState().isApiLoading = true;

    try {
      const response = await fn()

      useGlobalState().isApiLoading = false;

      return response;
    } catch (error) {
      //@ts-ignore
      useNotification().notify("error", `${error.message}`);
    } finally {
      useGlobalState().isApiLoading = false;
    }
  }
}

export default useDefaultApi;
