import GroupModel from "~/packages/core/models/GroupModel";
import {merge} from "lodash";
import {Groups} from "~/packages/core/interfaces/global";
import WebinarsCollection from "~/packages/core/models/training/WebinarsCollection";

class FeaturedWebinarModel extends GroupModel implements Groups {
    static permission_name: string = "webinar_featured"

    casts() {
        return merge(super.casts(), {
            id: "uid",
            active: "string",
            webinars: WebinarsCollection
        });
    }

    /**** GETTERS ****/
    /** Methods **/
    //TODO: Bind webinar options once webinar asset is up
    async selectOptions(query: any) {
        const webinarOptions = await new useAssets().getSearchedAsset('webinar', query, {
            fields: ['title', 'webinar_id'],
            order_direction: 'asc',
            search_fields: 'title,webinar_id'
        });

        return webinarOptions?.selectOptions;
    }
}

export default FeaturedWebinarModel;
