<template>
  <div class="mt-2 w-[15rem] sm:w-[30rem] py-2">
    <h4 class="text-gray-800 dark:text-white text-center mb-5">Audit Full Data View</h4>
    <div class="pb-4 text-sm my-2 text-gray-500 dark:text-gray-500">
      <p>User with id <code class="text-red-500">{{ data.user_id }}</code> has <code class="text-red-500">{{ data.event }}</code>.</p>
      <p>Engaged entity: <code class="text-red-500">{{ data.auditable_type }}</code></p>
      <p>Engaged id: <code class="text-red-500">{{ data.auditable_id }}</code><br></p>
      <p>Date: <code class="text-red-500">{{ data.created_at }}</code></p>
    </div>

    <div
        v-for="(value, key, index) in data.getModelData()"
        :key="key" class="relative shadow px-4 py-3 border-l-2 mb-4" :class="[borderClasses[index % borderClasses.length]]">
      <div class="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">{{ key }}</div>
      <div v-if="isObject(value)" class="ml-4 mt-2">
        <!-- Render nested object as list -->
        <ul class="list-disc list-inside space-y-1">
          <li
              v-for="(nestedValue, nestedKey) in value"
              :key="nestedKey"
              class="text-sm text-gray-900 dark:text-white flex justify-between py-1 border-b border-gray-200 dark:border-gray-700 last:border-0"
          >
            <span class="font-medium text-gray-500 dark:text-gray-400">{{ nestedKey }}:&nbsp;</span>
            <span>{{ nestedValue }}</span>
          </li>
        </ul>
      </div>
      <div v-else class="text-sm text-gray-900 dark:text-white mt-1">
        {{ value }}
      </div>
<!--      <custom-tools-audit-recursive-audit-field-renderer-->
<!--          :value="value"-->
<!--      />-->
    </div>
  </div>
</template>

<script setup lang="ts">
import Model from "~/packages/core/models/Model";
import { isObject } from "lodash";

const props = defineProps({
  data: {
    type: Model,
    required: true
  },
});

// Define border classes based on available colors in InfoCard.vue
const borderClasses = [
  "border-blue-500/30 hover:border-blue-500",
  "border-green-500/30 hover:border-green-500",
  "border-red-500/30 hover:border-red-500",
  "border-yellow-500/30 hover:border-yellow-500",
  "border-purple-500/30 hover:border-purple-500",
];
</script>