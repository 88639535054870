import { Payload } from "~/packages/core/types/Api"
import MembershipModel from "~/packages/core/models/MembershipModel";
import {useGlobalState} from "~/store/useGlobalState";
import GoBack from "~/packages/core/actions/utility/GoBack";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";

const useMemberships = () => {
    const membershipsApi = useApi().memberships
    const globalState = useGlobalState();
    const { notify } = useNotification()
    return {
        list: async (payload?: Payload) => {
            payload = payload || { per_page: 25 }
            return await membershipsApi.list(payload)
        },
        get: async (id:any, payload?: Payload, url?:any)=> {
            try {
                return await membershipsApi.get(id, url, payload)
            } catch (error){
                // @ts-ignore
                notify('error',"Something went wrong please try again!");
            }
        },
        update: async (model:MembershipModel, payload?:Payload) =>{
            globalState.isApiLoading = true;
            try {
                const membership = await membershipsApi.update(model.getModelData(), model.id, payload);
                if(membership.metadata.status === "200"){
                    NotifyByAPIResponse.execute(membership)
                }
            }
            catch(error) {
                // @ts-ignore
                notify('error',"Something went wrong please try again!");
            }
            finally {
                globalState.isApiLoading = false;
            }
        },
        create: async (model:MembershipModel) => {
            globalState.isApiLoading = true;
            try {
                const membership = await membershipsApi.create(model.getModelData())
                if(membership.metadata.status === "200"){
                    NotifyByAPIResponse.execute(membership)
                    GoBack.execute();
                }
            }
            catch (error){
                // @ts-ignore
                notify('error',"Something went wrong please try again!");
            }
            finally {
                globalState.isApiLoading = false;
            }
        },
        deleteMembership: async (id:any) => {
            try {
                const membership = await membershipsApi.delete(id);
                if(membership){
                    if(membership.meta.status === "200"){
                        NotifyByAPIResponse.execute(membership)
                    }
                    location.reload()
                }
            }
            catch (error){
                // @ts-ignore
                notify('error',"Something went wrong please try again!");
            }
        },
    }
}

export default useMemberships