import type {Payload} from "~/packages/core/types/Api";
import {useGlobalState} from "~/store/useGlobalState";
import type RoleModel from "~/packages/core/models/RoleModel";
import GoBack from "~/packages/core/actions/utility/GoBack";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";

class UseUsersRoles extends useDefaultApi{
    protected api = 'role'
    protected excluded = []

    async create(model: RoleModel, payload?:Payload, url?:string) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].create(
                model.getModelData(this.excluded), payload, url
            );

            if(entity.metadata?.status == 200) {
                // @ts-ignore
                useNotification().notify(
                    "success",
                    `Role ${entity.title} created!`,
                );
                // @ts-ignore
                // pass newly created role id to the initially created model (that didn't had an id) and attach permissions
                model.id = entity.id
                // @ts-ignore
                const permissions = await useApi()[this.api].createPermissions(model)

                if (permissions.value?.meta?.status == 200) {
                    useNotification().notify(
                        "success",
                        `Permissions successfully attached to role: ${entity.title} !`,
                    );
                }
            }

            if (entity.metadata?.status === '201') {
                NotifyByAPIResponse.execute(entity)
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }

    async update(model: RoleModel, payload?: Payload, url?:string) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const handlePermissionRelation =  await useApi()[this.api].updatePermissions(model)
            if(handlePermissionRelation){
                //@ts-ignore
                const entity = await useApi()[this.api].update(
                    model.getModelData(this.excluded),
                    model.id,
                    payload,
                    url
                );
                if (entity.metadata.status === "200") {
                    //@ts-ignore
                    NotifyByAPIResponse.execute(entity)
                    GoBack.execute();
                }
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }
}

export default UseUsersRoles