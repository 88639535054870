import EventModel from '~/packages/core/models/content/EventModel';
import Collection from '~/packages/core/models/Collection';

class EventsCollection extends Collection {
    /**
     * Define associated model to the EventsCollection
     */
    model(): typeof EventModel {
        return EventModel;
    }

    /*Getters*/
    get eventSelectOptions() {
        return this.map((asset: any) => {
            return { value: asset.id, title: asset.id + ' - ' + asset.title, id: asset.id }
        });
    }
}

export default EventsCollection;