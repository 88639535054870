import Model from "~/packages/core/models/Model";
import type { ModelOptions } from "~/packages/core/models/BaseModel";
class CampaignAssetModel extends Model {
  static override permission_name: string = "cs_campaign";
  override casts() {
    return {
      history_id: "number",
      cs_asset_id: "number",
      status: "number",
      asset_type: "string",
      asset_id: "number",
      campaign_id: "number",
      guaranteed: "number",
      leads_guaranteed: "string",
      todate_leads: "number",
      conditions: "string",
      cma_lead_rate: "number",
      asset_type_raw: "number",
      notes:"string",
      note:"string",
      asset: "any"
    };
  }

  get id() {
    return this.cs_asset_id
  }

  get is_no_limit() {
    return this.guaranteed === 0;
  }

  set is_no_limit(value: boolean) {
    this.guaranteed = value ? 0 : 1;
  }

  /**
   * uncomment this method in the model, if current model has different, key name, than
   * the default ('id')
   */
  override getDefaultOptions(): ModelOptions {
    return {
      identifier: "history_id",
    };
  }

}

export default CampaignAssetModel;
