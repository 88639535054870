import FeaturedEventsCollection from "~/packages/core/models/FeaturedEventsCollection";
import FeaturedWebinarsCollection from "~/packages/core/models/FeaturedWebinarsCollection";
import AdvisorsBoardCollection from "~/packages/core/models/clientservices/AdvisorsBoardCollection";
import FeaturedMenusCollection from "~/packages/core/models/tools/FeaturedMenusCollection";

const collectionByType = {
   featured_events: FeaturedEventsCollection,
   featured_webinars: FeaturedWebinarsCollection,
   featured_menu : FeaturedMenusCollection,
   board_of_advisers : AdvisorsBoardCollection
};

const useGroupsCollection = (type: string) => {
    //@ts-ignore
    const collection = collectionByType[type];
    return { collection }
    // let collection = new collectionByType[type](response);
    // return collection
};
export default useGroupsCollection;
