/*
* useCategorySponsors extends defaultApi composable to use Api state and Common Api Notifications
 */

import BaseModel from "~/packages/core/models/BaseModel";
import {useGlobalState} from "~/store/useGlobalState";
import type {Payload} from "~/packages/core/types/Api";
import GoBack from "~/packages/core/actions/utility/GoBack";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";

class useCategorySponsors extends useDefaultApi {
    protected api:string = 'category_sponsors'
    protected excluded:string[] = ['category']

    async searchCategorySponsors(query: string, payload?:any) {
        //@ts-ignore
        return await useApi()[this.api].searchCategories(query,payload)
    }

    async create(model: BaseModel) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].create(model.getModelData(this.excluded));
            if(entity){
                if (entity.metadata.status === "200") {
                    // @ts-ignore
                    NotifyByAPIResponse.execute(entity)
                    GoBack.execute();
                }
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }

    async update(model: BaseModel, payload?: Payload) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].update(
                model.getModelData(this.excluded),
                model.id,
                payload,
            );
            if (entity.metadata.status === "200") {
                //@ts-ignore
                NotifyByAPIResponse.execute(entity)
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }
}

export default  useCategorySponsors;