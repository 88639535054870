import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import ArticleModel from "~/packages/core/models/ArticleModel";
import InterviewModel from "~/packages/core/models/InterviewModel";
import BlogsModel from "~/packages/core/models/content/BlogsModel";
import WhitepaperModel from "~/packages/core/models/WhitepaperModel";
import HandbookModel from "~/packages/core/models/HandbookModel";
import EventModel from "~/packages/core/models/content/EventModel";
import FeaturedEventModel from "~/packages/core/models/FeaturedEventModel";
import categoryModel from "~/packages/core/models/CategoryModel";
import RelatedAssetsModel from "~/packages/core/models/RelatedAssetsModel";
import WebinarModel from "~/packages/core/models/training/WebinarModel";
import FeaturedWebinarModel from "~/packages/core/models/FeaturedWebinarModel";
import UserModel from "~/packages/core/models/UserModel";
import BadWordsModel from "~/packages/core/models/BadWordsModel";
import TitleLevelsModel from "~/packages/core/models/TitleLevelsModel";
import JobFunctionModel from "~/packages/core/models/JobFunctionModel";
import CompanyModel from "~/packages/core/models/CompanyModel";
import CompanyTypeModel from "~/packages/core/models/CompanyTypeModel";
import CompanyIndustriesModel from "~/packages/core/models/CompanyIndustriesModel";
import EmailTemplateModel from "~/packages/core/models/emailcenter/EmailTemplateModel";
import CustomPopupModel from "~/packages/core/models/marketing/CustomPopupModel";
import BlockedPopupModel from "~/packages/core/models/marketing/BlockedPopupModel";
import PressreleaseModel from "~/packages/core/models/PressreleaseModel";
import VendorDirectoryModel from "~/packages/core/models/marketing/VendorDirectoryModel";
import InterstitialModel from "~/packages/core/models/marketing/InterstitialModel";
import CaptionModel from "~/packages/core/models/tools/CaptionModel";
import FeaturedMenuModel from "~/packages/core/models/tools/FeaturedMenuModel";
import AuthorModel from "~/packages/core/models/AuthorModel";
import FlaggedUserModel from "~/packages/core/models/userpolicy/FlaggedUserModel";
import ManageReferralModel from "~/packages/core/models/emailcenter/ManageReferralModel";
import AdminUserModel from "~/packages/core/models/users/AdminUserModel";
import CategorySponsorModel from "~/packages/core/models/content/CategorySponsorModel";
import CompanyAliasModel from "~/packages/core/models/userpolicy/CompanyAliasModel";
import PopupModel from "~/packages/core/models/marketing/PopupModel";
import AssetModel from "~/packages/core/models/AssetModel";
import CampaignModel from "~/packages/core/models/clientservices/CampaignModel";
import TranscodingModel from "~/packages/core/models/tools/TranscodingModel";
import ImportModel from "~/packages/core/models/tools/ImportModel";
import AdvisorsBoardModel from "~/packages/core/models/clientservices/AdvisorsBoardModel";
import AssetCategoriesModel from "~/packages/core/models/reports/AssetCategoriesModel";
import AssetViewsModel from "~/packages/core/models/reports/AssetViewsModel";
import PermissionModel from "~/packages/core/models/PermissionModel";
import RoleModel from "~/packages/core/models/RoleModel";
import ChangelogModel from "~/packages/core/models/content/ChangelogModel";
import AnnouncementModel from "~/packages/core/models/tools/AnnouncementModel";
import AuditModel from "~/packages/core/models/tools/AuditModel";

export default class GetMenuData extends BaseStaticAction {
    static execute() {
        return [
            {
                title: "Dashboard", to: "/dashboard"
            },
            {
                title: "Content",
                subnav: [
                    {
                        title: "Articles",
                        to: "/content/article",
                        add_url: "/content/article/add",
                        icon: "/icons/Megamenu/article-line.svg",
                        required_ability: ArticleModel.getReadActionName() // in order to view articles list, read permissions are required
                    },
                    {
                        title: "Videos",
                        to: "/content/video",
                        add_url: "/content/video/add",
                        icon: "/icons/Megamenu/video-line.svg",
                        required_ability: ArticleModel.getReadActionName()
                    },
                    {
                        title: "Interviews",
                        to: "/content/interview",
                        add_url: "/content/interview/add",
                        icon: "/icons/Megamenu/vidicon-line.svg",
                        required_ability: InterviewModel.getReadActionName()
                    },

                    {
                        title: "Blogs",
                        icon: "/icons/Megamenu/pen-nib-line.svg",
                        subnav: [
                            {
                                title: "New Blog Post", to: "/content/blogs/addpost",
                                required_ability: BlogsModel.getReadActionName()
                            },
                            {
                                title: "All Blog Posts",
                                to: "/content/blogs",
                                required_ability: BlogsModel.getReadActionName()
                            },
                            {
                                title: "Blog Sites",
                                to: "/content/blogs/blogsites",
                                add_url: "/content/blogs/blogsites/addblog",
                                required_ability: BlogsModel.getReadActionName()
                            },
                            {
                                title: "Bloggers",
                                to: "/content/blogs/bloggers",
                                add_url: "/content/blogs/bloggers/add",
                                required_ability: BlogsModel.getReadActionName()
                            }
                        ],
                        required_ability: BlogsModel.getReadActionName()
                    },
                    {
                        title: "White Papers",
                        to: "/content/whitepaper",
                        add_url: "/content/whitepaper/add",
                        icon: "/icons/Megamenu/newspaper-line.svg",
                        required_ability: WhitepaperModel.getReadActionName()
                    },
                    {
                        title: "Handbooks",
                        to: "/content/handbook",
                        add_url: "/content/handbook/add",
                        icon: "/icons/Megamenu/book-line.svg",
                        required_ability: HandbookModel.getReadActionName()
                    },
                    // {
                    //     title: "Content Hubs",
                    //     to: "/content/contenthubs",
                    //     icon: "/icons/Megamenu/book-line.svg"
                    // },
                    {
                        title: "Events",
                        to: "/content/event",
                        add_url: "/content/event/add",
                        icon: "/icons/Megamenu/calendar-event-line.svg",
                        required_ability: EventModel.getReadActionName()
                    },
                    {
                        title: "Featured Events",
                        to: "/content/featuredevents",
                        icon: "/icons/Megamenu/calendar-event-line.svg",
                        required_ability: FeaturedEventModel.getReadActionName()
                    },

                    {
                        title: "Categories",
                        icon: "/icons/Megamenu/list-unordered.svg",
                        subnav: [
                            {
                                title: "Categories List",
                                to: "/content/categories",
                                add_url: "/content/categories/add",
                                required_ability: categoryModel.getReadActionName()
                            },
                            {
                                title: "Categories Sponsors",
                                to: "/content/categories/sponsors",
                                add_url: "/content/categories/sponsors/add",
                                required_ability: CategorySponsorModel.getReadActionName()
                            }
                        ],
                        required_ability: categoryModel.getReadActionName() || CategorySponsorModel.getReadActionName()
                    },
                    {
                        title: "Changelog",
                        to: "/content/changelog",
                        icon: "/icons/Megamenu/file-list-line.svg",
                        required_ability: ChangelogModel.getReadActionName()
                    },
                    {
                        title: "SEO Tips",
                        to: "/content/seotips",
                        icon: "/icons/Megamenu/file-search-line.svg"
                    },
                    {
                        title: "Clone Assets",
                        to: "/content/cloneasset",
                        icon: "/icons/Megamenu/file-copy-line.svg",
                        required_ability: ArticleModel.getReadActionName()
                    },
                    {
                        title: "Related Assets Override",
                        to: "/content/assetoverride",
                        icon: "/icons/Megamenu/file-copy-line.svg",
                        required_ability: RelatedAssetsModel.getReadActionName()
                    }
                ]
            },
            {
                title: "Training",

                subnav: [
                    {
                        title: "Webinars",
                        to: "/training/webinar",
                        add_url: "/training/webinar/add",
                        icon: "/icons/Megamenu/calendar-event-line.svg",
                        required_ability: WebinarModel.getReadActionName()
                    },
                    {
                        title: "Featured Webinars",
                        to: "/training/webinar/featured",
                        icon: "/icons/Megamenu/calendar-event-line.svg",
                        required_ability: FeaturedWebinarModel.getReadActionName()
                    },
                    {
                        title: "Webinar conversion",
                        to: "/training/webinar/conversion",
                        icon: "/icons/Megamenu/arrow-left-right-line.svg",
                        required_ability: WebinarModel.getReadActionName()
                    },
                    // {
                    //     title: "Webinar Bulk Register",
                    //     to: "/training/webinar/bulkregister",
                    //     icon: "/icons/Megamenu/clipboard-line.svg"
                    // }
                ]
            },
            {
                title: "Users",
                subnav: [
                    {
                        title: "Website Users",
                        to: "/users",
                        add_url: "/users/add",
                        icon: "/icons/Megamenu/user-3-line.svg",
                        required_ability: UserModel.getReadActionName()
                    },
                    {
                        title: "Admin Users",
                        to: "/users/admin",
                        add_url: "/users/admin/add",
                        icon: "/icons/Megamenu/user-star-line.svg",
                        required_ability: AdminUserModel.getReadActionName()
                    },
                    {
                        title: "Permissions",
                        to: "/users/permissions",
                        add_url: "/users/permissions/add",
                        icon: "/icons/Megamenu/user-forbid-line.svg",
                        required_ability: PermissionModel.getReadActionName()
                    },
                    {
                        title: "Roles",
                        to: "/users/roles",
                        add_url: "/users/roles/add",
                        icon: "/icons/Megamenu/group-line.svg",
                        required_ability: RoleModel.getReadActionName()
                    },
                    // {
                    //     title: "Memberships",
                    //     to: "/users/membership",
                    //     icon: "/icons/Megamenu/group-line.svg",
                    //     required_ability: MembershipModel.getReadActionName()
                    // },
                    // {
                    //     title: "Groups",
                    //     to: "/users/groups",
                    //     icon: "/icons/Megamenu/group-2-line.svg",
                    //     required_ability: UserGroupModel.getReadActionName()
                    // }
                ]
            },
            {
                title: "User Registration Policy",

                subnav: [
                    {
                        title: "Bad Words",
                        to: "/userpolicy/badwords",
                        add_url: "/userpolicy/badwords/add",
                        icon: "/icons/Megamenu/thumb-down-line.svg",
                        required_ability: BadWordsModel.getReadActionName()
                    },
                    {
                        title: "Title Levels",
                        to: "/userpolicy/titlelevel",
                        add_url: "/userpolicy/titlelevel/add",
                        icon: "/icons/Megamenu/t-box-line.svg",
                        required_ability: TitleLevelsModel.getReadActionName()
                    },
                    {
                        title: "Job Functions",
                        to: "/userpolicy/jobfunction",
                        add_url: "/userpolicy/jobfunction/add",
                        icon: "/icons/Megamenu/function-line.svg",
                        required_ability: JobFunctionModel.getReadActionName()
                    },
                    {
                        title: "Companies",
                        to: "/userpolicy/companies",
                        add_url: "/userpolicy/companies/add",
                        icon: "/icons/Megamenu/building-2-line.svg",
                        required_ability: CompanyModel.getReadActionName()
                    },
                    {
                        title: "Companies Aliases",
                        to: "/userpolicy/companyalias",
                        add_url: "/userpolicy/companyalias/add",
                        icon: "/icons/Megamenu/hotel-line.svg",
                        required_ability: CompanyAliasModel.getReadActionName()
                    },
                    {
                        title: "Companies Types",
                        to: "/userpolicy/companytype",
                        add_url: "/userpolicy/companytype/add",
                        icon: "/icons/Megamenu/building-3-line.svg",
                        required_ability: CompanyTypeModel.getReadActionName()
                    },
                    {
                        title: "Companies Industries",
                        to: "/userpolicy/companyindustry",
                        add_url: "/userpolicy/companyindustry/add",
                        icon: "/icons/Megamenu/database-line.svg",
                        required_ability: CompanyIndustriesModel.getReadActionName()
                    },
                    {
                        title: "Flagged Users",
                        to: "/userpolicy/flaggedusers",
                        icon: "/icons/Megamenu/flag-2-line.svg",
                        required_ability: FlaggedUserModel.getReadActionName()
                    },
                    // {
                    //     title: "ISMG Registrations",
                    //     to: "/userpolicy/ismgregistrations",
                    //     icon: "/icons/Megamenu/edit-box-line.svg"
                    // }
                ]
            },
            {
                title: "Newsletters",

                subnav: [
                    {
                        title: "Email Center",
                        to: "/newsletters",
                        icon: "/icons/Megamenu/mail-line.svg",
                        required_ability: EmailTemplateModel.getReadActionName()
                    },
                    {
                        title: "Email Archive",
                        to: "/newsletters/emailarchive",
                        icon: "/icons/Megamenu/inbox-archive-line.svg",
                        required_ability: EmailTemplateModel.getReadActionName()
                    },
                    // {
                    //     title: "Banner Ads",
                    //     to: "/newsletters/bannerads",
                    //     icon: "/icons/Megamenu/advertisement-line.svg"
                    // },
                    {
                        title: "Manage Referral",
                        to: "/newsletters/managereferral",
                        icon: "/icons/Megamenu/hand-coin-line.svg",
                        required_ability: ManageReferralModel.getReadActionName()
                    }
                ]
            },
            // {
            //     title: "Job Board",
            //
            //     subnav: [
            //         {
            //             title: "Jobs",
            //             to: "/jobboard/jobs",
            //             icon: "/icons/Megamenu/user-heart-line.svg"
            //         },
            //         {
            //             title: "Employers & Candidates",
            //             to: "/jobboard/employercandidates",
            //             icon: "/icons/Megamenu/team-line.svg"
            //         },
            //         {
            //             title: "Orders",
            //             to: "/jobboard/orders",
            //             icon: "/icons/Megamenu/coins-line.svg"
            //         },
            //         {
            //             title: "Settings",
            //             to: "/jobboard/settings",
            //             icon: "/icons/Megamenu/settings-3-line.svg"
            //         }
            //     ]
            // },
            {
                title: "Marketing",

                subnav: [
                    {
                        title: "Pop-ups",
                        to: "/marketing/popups",
                        add_url: "/marketing/popups/add",
                        icon: "/icons/Megamenu/file-copy-line.svg",
                        required_ability: PopupModel.getReadActionName()
                    },
                    {
                        title: "Custom Pop-ups",
                        to: "/marketing/custompopups",
                        icon: "/icons/Megamenu/checkbox-multiple-line.svg",
                        required_ability: CustomPopupModel.getReadActionName()
                    },
                    {
                        title: "Block Pop-ups by Asset",
                        to: "/marketing/blockpopups",
                        add_url: "/marketing/blockpopups/add",
                        icon: "/icons/Megamenu/file-copy-fill.svg",
                        required_ability: BlockedPopupModel.getReadActionName()
                    },
                    {
                        title: "Press Releases",
                        to: "/marketing/pressrelease",
                        add_url: "/marketing/pressrelease/add",
                        icon: "/icons/Megamenu/newspaper-line.svg",
                        required_ability: PressreleaseModel.getReadActionName()
                    },
                    {
                        title: "Vendor Directory",
                        to: "/marketing/vendordirectory",
                        add_url: "/marketing/vendordirectory/add",
                        icon: "/icons/Megamenu/folder-user-line.svg",
                        required_ability: VendorDirectoryModel.getReadActionName()
                    },
                    // {
                    //     title: "Self Serve Ads",
                    //     to: "/marketing/selfserveads",
                    //     icon: "/icons/Megamenu/advertisement-line.svg"
                    // },
                    // {
                    //     title: "Special Alerts",
                    //     to: "/marketing/specialalerts",
                    //     icon: "/icons/Megamenu/spam-line.svg"
                    // },
                    {
                        title: "Interstitial",
                        to: "/marketing/interstitial",
                        add_url: "/marketing/interstitial/add",
                        icon: "/icons/Megamenu/book-read-fill.svg",
                        required_ability: InterstitialModel.getReadActionName()
                    },
                    {
                        title: "Asset Ads",
                        to: "/marketing/assetads",
                        add_url: "/marketing/assetads/add",
                        icon: "/icons/Megamenu/advertisement-line.svg",
                        required_ability: AssetModel.getReadActionName()
                    },
                    // {
                    //     title: "Summit Prices & Coupons",
                    //     to: "/marketing/summitcoupons",
                    //     icon: "/icons/Megamenu/coupon-line.svg"
                    // },
                    // {
                    //     title: "Global Coupons",
                    //     to: "/marketing/globalcoupons",
                    //     icon: "/icons/Megamenu/coupon-3-line.svg"
                    // },
                    // {
                    //     title: "Edit Promotions",
                    //     to: "/marketing/editpromotions",
                    //     icon: "/icons/Megamenu/image-edit-line.svg"
                    // },
                    // {
                    //     title: "Browser Notifications",
                    //     to: "/marketing/browsernotification",
                    //     icon: "/icons/Megamenu/notification-badge-line.svg"
                    // },
                    // {
                    //     title: "Invoices",
                    //     to: "/dashboard",
                    //     icon: "/icons/Megamenu/file-list-3-line.svg"
                    // },
                    {
                        title: "Pop-up Levels",
                        to: "/marketing/popuplevels",
                        icon: "/icons/Megamenu/checkbox-multiple-fill.svg",
                        required_ability: CampaignModel.getReadActionName()
                    },
                    // {
                    //     title: "All Orders",
                    //     to: "/marketing/allorder",
                    //     icon: "/icons/Megamenu/money-dollar-box-line.svg"
                    // }
                ]
            },
            {
                title: "Tools",

                subnav: [
                    {
                        title: "Video Transcoding",
                        to: "/tools/videotranscoding",
                        icon: "/icons/Megamenu/live-line.svg",
                        required_ability: TranscodingModel.getReadActionName()
                    },
                    {
                        title: "Video/Audio Captions",
                        to: "/tools/captions",
                        icon: "/icons/Megamenu/closed-captioning-line.svg",
                        required_ability: CaptionModel.getReadActionName()
                    },
                    {
                        title: "Embed Video Code",
                        to: "/tools/embedvideocode",
                        icon: "/icons/Megamenu/movie-line.svg"
                    },
                    {
                        title: "Reset Password Links",
                        to: "/tools/resetpasswordlinks",
                        icon: "/icons/Megamenu/key-line.svg"
                    },
                    // {
                    //     title: "Extract Eloqua Data",
                    //     to: "/tools/extracteloquadata",
                    //     icon: "/icons/Megamenu/database-2-line.svg"
                    // },
                    {
                        title: "Import Users/Companies",
                        to: "/tools/importcontactscompany",
                        icon: "/icons/Megamenu/group-line.svg",
                        required_ability: ImportModel.getReadActionName()
                    },
                    // {
                    //     title: "Merge Companies",
                    //     to: "/tools/mergecompanies",
                    //     icon: "/icons/Megamenu/group-line.svg"
                    // },
                    {
                        title: "Trending Bar",
                        to: "/tools/trendingbar",
                        icon: "/icons/Megamenu/bar-chart-line.svg",
                        required_ability: FeaturedMenuModel.getReadActionName()
                    },
                    // {
                    //     title: "Webinar Access Tokens",
                    //     to: "/tools/webinarsaccesstoken",
                    //     icon: "/icons/Megamenu/coupon-line.svg"
                    // },
                    {
                        title: "Pdf Upload",
                        to: "/tools/upload-resource/pdf",
                        icon: "/icons/Megamenu/file-upload-line.svg"
                    },
                    {
                        title: "Image Upload",
                        to: "/tools/upload-resource/image",
                        icon: "/icons/Megamenu/file-upload-line.svg"
                    },
                    // {
                    //     title: "Events Attendees Credits",
                    //     to: "/tools/eventattendeescredits",
                    //     icon: "/icons/Megamenu/copper-diamond-line.svg"
                    // },
                    {
                        title: "Emails Md5 Hash",
                        to: "/tools/emailmdhash",
                        icon: "/icons/Megamenu/mail-settings-line.svg",
                        required_ability: "email_hash_md5_gate_read"
                    },
                    {
                        title: "Announcements",
                        to: "/tools/announcements",
                        icon: "/icons/Megamenu/clipboard-line.svg",
                        required_ability: AnnouncementModel.getReadActionName()
                    },
                    {
                        title: "Audits",
                        to: "/tools/audits",
                        icon: "/icons/Megamenu/file-list-line.svg",
                        required_ability: AuditModel.getReadActionName()
                    },
                ]
            },
            {
                title: "Reports",

                subnav: [
                    // {
                    //     title: "Sales Force",
                    //     to: "/reports/salesforce",
                    //     icon: "/icons/Megamenu/salesforce.png"
                    // },
                    // {
                    //     title: "Analytics",
                    //     to: "/reports/analytics",
                    //     icon: "/icons/Megamenu/line-chart-line.svg"
                    // },
                    // {
                    //     title: "Searched Terms",
                    //     to: "/reports/searchedterms",
                    //     icon: "/icons/Megamenu/search-2-line.svg"
                    // },
                    // {
                    //     title: "Media Kit DL",
                    //     to: "/reports/mediakit",
                    //     icon: "/icons/Megamenu/clapperboard-line.svg"
                    // },
                    // {
                    //     title: "Webinar Matrix",
                    //     to: "/reports/webinarsmatrix",
                    //     icon: "/icons/Megamenu/line-chart-line.svg"
                    // },
                    // {
                    //     title: "Check Blacklist",
                    //     to: "/reports/checkblacklist",
                    //     icon: "/icons/Megamenu/dashboard-line.svg"
                    // },
                    {
                        title: "Asset Categories",
                        to: "/reports/assetcategories",
                        icon: "/icons/Megamenu/list-ordered.svg",
                        required_ability: AssetCategoriesModel.getReadActionName()
                    },
                    {
                        title: "Asset Views",
                        to: "/reports/assetsviews",
                        icon: "/icons/Megamenu/eye-line.svg",
                        required_ability: AssetViewsModel.getReadActionName()
                    }
                ]
            },
            {
                title: "ISMG Client Services",

                subnav: [
                    {
                        title: "Authors",
                        to: "/clientservices/authors",
                        add_url: "/clientservices/authors/add",
                        icon: "/icons/Megamenu/group-line.svg",
                        required_ability: AuthorModel.getReadActionName()
                    },
                    {
                        title: "Campaigns",
                        to: "/clientservices/campaigns",
                        add_url: "/clientservices/campaigns/add",
                        icon: "/icons/Megamenu/speaker-line.svg",
                        required_ability: CampaignModel.getReadActionName()
                    },
                    // {
                    //     title: "Campaign Users",
                    //     to: "/clientservices/campaignusers",
                    //     icon: "/icons/Megamenu/folder-user-line.svg"
                    // },
                    // {
                    //     title: "CR - Incomplete Leads",
                    //     to: "/dashboard",
                    //     icon: "/icons/Megamenu/user-received-2-line.svg"
                    // },
                    // {
                    //     title: "Update Incomplete Leads",
                    //     to: "/clientservices/updateincompleteleads",
                    //     icon: "/icons/Megamenu/ball-pen-line.svg"
                    // },
                    // {
                    //     title: "ON 24 Campaign",
                    //     to: "/clientservices/oncampaign",
                    //     icon: "/icons/Megamenu/24-hours-line.svg"
                    // },
                    // {
                    //     title: "CISCO Campaign",
                    //     to: "/clientservices/ciscocampaign",
                    //     icon: "/icons/Megamenu/speaker-3-line.svg"
                    // },
                    // {
                    //     title: "IBM/Ogilvy Campaign",
                    //     to: "/clientservices/ibmcampaign",
                    //     icon: "/icons/Megamenu/speaker-2-line.svg"
                    // },
                    // {
                    //     title: "Fortscale Campaign",
                    //     to: "/clientservices/fortscalecampaign",
                    //     icon: "/icons/Megamenu/speaker-line.svg"
                    // },
                    // {
                    //     title: "HID Campaign",
                    //     to: "/clientservices/hidcampaign",
                    //     icon: "/icons/Megamenu/speaker-2-line.svg"
                    // },
                    {
                        title: "Board of Advisers",
                        to: "/clientservices/advisersboard",
                        icon: "/icons/Megamenu/user-star-line.svg",
                        required_ability: AdvisorsBoardModel.getReadActionName()
                    },
                    {
                        title: "Athena Asset Keywords",
                        to: "/clientservices/athena",
                        icon: "/icons/Megamenu/contacts-book-upload-line.svg"
                    }
                ]
            }
        ]
    }
}