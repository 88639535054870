/*
* useEmailTemplate extends defaultApi composable to use Api state and Common Api Notifications
 */

class useEmailTemplate extends useDefaultApi {
  protected api: string = "email_templates";
  protected excluded: string[] = [
    "AssetOptions",
    "FooterIdOptions",
    "content_object",
    "directLinkOptions",
    "featuredItemOptions",
    "referralOptions",
    "referral_segment",
    "sidebarOptions",
    "templateTypes",
    "type_data",
    "webinar_timezone_options",
  ];
}

export default  useEmailTemplate;