import {ref, h, Teleport, render} from 'vue';
import SharedBlocksModal from '~/components/shared/blocks/Modal.vue';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('popUp', {
        mounted(el, binding) {
            const {component, props, onConfirm, onCancel} = binding.value;

            // Modal visibility state
            const modalVisible = ref(false);

            const openModal = () => {
                modalVisible.value = true;
                renderModal();
            };

            const closeModal = () => {
                modalVisible.value = false;
                destroyModal();
            };

            const handleCancel = () => {
                if (onCancel) {
                    onCancel();
                }
                closeModal();
            };

            // Attach event listener to trigger modal
            el.addEventListener('click', openModal);

            // Define modal content rendering logic
            let modalContainer = document.createElement('div');
            document.body.appendChild(modalContainer);

            const renderModal = () => {
                const vnode = h(
                    Teleport, {to: 'body'}, [
                        h(SharedBlocksModal, {
                            modelValue: modalVisible.value,
                            'onUpdate:modelValue': (value) => {
                                modalVisible.value = value;
                                if (!value) destroyModal(); // Close modal if modelValue is false
                            },
                        }, {
                            default: () => h(component, {
                                ...props,
                                onConfirm: async (...args) => {
                                    if (onConfirm) {
                                        await onConfirm(...args);
                                    }
                                    closeModal();
                                },
                                onCancel: handleCancel
                            })
                        })
                    ]
                );
                render(vnode, modalContainer); // Render vnode into modalContainer
            };

            const destroyModal = () => {
                render(null, modalContainer); // Unmount the modal
                if (modalContainer.parentNode) {
                    modalContainer.parentNode.removeChild(modalContainer);
                }
            };

            // Clean up when directive is unmounted
            el._modalContainer = modalContainer;

            el._destroyEvent = () => {
                el.removeEventListener('click', openModal);
            };
        },
        unmounted(el) {
            el._destroyEvent?.();
            if (el._modalContainer) {
                el._modalContainer.remove();
            }
        },
    });
});
