import Model from "~/packages/core/models/Model";

class AuditModel extends Model {
    override casts(): {} {
        return {
            id: "number",
            user_type: "string",
            login_access_token_id: "string",
            user_id: "string",
            event: "string",
            auditable_type: "string",
            auditable_id: "number",
            old_values: "object",
            new_values: "object",
            url: "string",
            tags: "string",
            batch: "string",
            session_id: "string",
            meta: "object",
            created_at: "string",
            updated_at: "string",
        };
    }
}

export default AuditModel;
