export default function useGridRefresh() {
  return {
    /**
     * useState reactive state designed for cross-component state management in Nuxt
     */
    masterRefreshKey: useState("masterRefreshKey", () => 0),
    transcodedRefreshKey: useState("transcodedRefreshKey", () => 0),
    campaignRefreshKey: useState("campaignRefreshKey", () => 0),
    entityRefreshKey: useState("entityRefreshKey", () => 0),
  };
}
