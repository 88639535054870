import Collection from "~/packages/core/models/Collection";
import CampaignIndustryModel from "~/packages/core/models/clientservices/CampaignIndustry";

class CampaignIndustriesCollection extends Collection {
    override model(): typeof CampaignIndustryModel {
        return CampaignIndustryModel;
    }

    /** Getters **/
    get selectOptions(): {} {
        return this.map((campaignIndustry: CampaignIndustryModel) => {
            return { name: campaignIndustry.name, id: campaignIndustry.id }
        });
    }
}

export default CampaignIndustriesCollection;