import Collection from "~/packages/core/models/Collection";
import AnnouncementStatisticsModel from "~/packages/core/models/tools/AnnouncementStatisticsModel";

class AnnouncementsStatisticsCollection extends Collection {
    /**
     * Define associated model to the CaptionsCollection
     */
    override model(): typeof AnnouncementStatisticsModel {
        return AnnouncementStatisticsModel;
    }
}

export default AnnouncementsStatisticsCollection;