import Vue3Toastify, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

//Package Docs : https://vue3-toastify.js-bridge.com/
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(Vue3Toastify, {
        autoClose: 5000,
        theme: "auto",
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        clearOnUrlChange: false // Keep the toast across page navigation
    });

    return {
        provide: {
            toast,
        },
    };
});
