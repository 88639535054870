import Model from "~/packages/core/models/Model";
import type {ModelOptions} from "~/packages/core/models/BaseModel";
import AuthorModel from "~/packages/core/models/AuthorModel";
import CampaignUsersCollection from "~/packages/core/models/clientservices/CampaignUsersCollection";
import ImportantFilesCollection from "~/packages/core/models/clientservices/ImportantFilesCollection";
import CampaignIndustriesCollection from "~/packages/core/models/clientservices/CampaignIndustriesCollection";

class CampaignModel extends Model {
  static permission_name: string = "cs_campaign";
  static override exempt_fields:string[] = [
    'duplicate_leads',
    'marketo_exclusion_cs_campaign_lead_filter_id'
  ]
  casts(): {} {
    return {
      campaign_id: "number",
      status: "number",
      author_id: "number",
      delivery: "array",
      guaranteed: "string",
      todate_leads: "number",
      conditions: "string",
      performance: "string",
      sample_leads: "string",
      summary: "string",
      dedicated_emails_sent: "string",
      dedicated_emails_open: "string",
      dedicated_emails_click: "string",
      enews_sent: "string",
      enews_open: "string",
      ads_impressions: "string",
      popup_days: "string",
      recommendations: "string",
      cname: "string",
      start_date: "string",
      end_date: "string",
      planned_start_date: "string",
      planned_end_date: "string",
      site: "string",
      duration: "string",
      duration_months: "string",
      aum_start: "string",
      aum_end: "string",
      employees_start: "string",
      employees_end: "string",
      titles: "string",
      geography: "string",
      geography_other: "string",
      duplicate_leads: "string",
      timeline: "string",
      industry_type: "string",
      sales_rep: "string",
      camp_manager: "string",
      appendix_sign_date: "string",
      target_start_date: "string",
      cs_campaigns_lead_filter_id: "number",
      screen_landing: "string",
      sample_leads_csv: "string",
      screen_email: "string",
      screen_enews: "string",
      screen_ads1: "string",
      screen_ads2: "string",
      screen_popups: "string",
      link_landing: "string",
      link_enews: "string",
      marketo_exclusion_list_id: "number",
      marketo_exclusion_cs_campaign_lead_filter_id: "number",
      marketo_exclusion_all: "number",
      cma_leads_week_needed: "number",
      computed_todate_leads: "number",
      computed_completed: "string",
      computed_status: "string",
      history: "array",
      authors: AuthorModel,
      industries: CampaignIndustriesCollection,
      contacts: CampaignUsersCollection,
      important_files: ImportantFilesCollection
    };
  }

  /** GETTERS **/

  get id() {
    return this.campaign_id;
  }

  getDefaultOptions(): ModelOptions {
    return {
      identifier: "campaign_id",
    };
  }

  get asset_id(): number {
    return this.campaign_id;
  }

  /** GETTERS END **/
  
  /** SETTERS END **/

  statusOptions = [
    {optionsValue: 0, optionsText: "Inactive"},
    {optionsValue: 1, optionsText: "Pending"},
    {optionsValue: 2, optionsText: "Current"},
    {optionsValue: 3, optionsText: "Problematic"},
    {optionsValue: 4, optionsText: "Completed"},
  ]

  duplicateLeads = [
    { optionsValue: "accept_per_asset", optionsText: "Accepted per asset (submit the same person every time he/she downloads asset)"},
    { optionsValue: "accept_per_multi_asset", optionsText: "Accepted per multiple assets (submit the same person only once per asset download)"},
    { optionsValue: "no_duplicate", optionsText: "No duplicate leads accepted"},
  ]
}

export default CampaignModel;