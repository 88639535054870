import MadminUserModel from "~/packages/core/models/MadminUserModel";
import { useAuthStore } from "~/store/AuthStore";
import MainProcess from "~/config/Constants/MainProcess";
import LoginProcess from "~/config/Constants/LoginProcess";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";

const useAuth = () => {
  const userApi = useApi().auth;
  const authStore = useAuthStore();
  const { notify } = useNotification();

  return {
    login: async () => {
      // @ts-ignore
      let response = await userApi.login(<MadminUserModel>authStore.auth);

      if (response && response.data.auth && response.data.token) {
        // save user in store
        authStore.store(<MadminUserModel>new MadminUserModel(response.data.auth), response.data.token);

        await authStore.setAuthorization(); // setting authorization
        const { $getPreviousUrl, $NuxtRouter } = useNuxtApp()

        // redirect user back to the latest page, before being sent to login or to the base path if no previous page exists yet
        return $NuxtRouter.push($getPreviousUrl() && $getPreviousUrl().indexOf(LoginProcess.LOGIN_PAGE) === -1 ? $getPreviousUrl() : MainProcess.BASE_PATH)
      } else {
        notify('error', 'Invalid Credentials!')
      }
    },
    azureLogin: () => {
      // @ts-ignore
      userApi.azureLogin()
    },
    logout: async () => {
      //@ts-ignore
      let response = await userApi.logout();
      // if server logout was a success
      if(response.value?.meta?.success) {
          authStore.logout()
      }
      return navigateTo(LoginProcess.LOGIN_PAGE);
    },
    getAuthenticatedUser: () => {
      return authStore.getAuthenticatedUser()
    },
    verifyToken: async () => {
      return await userApi.verifyToken()
    },
    getAuthorizationPermissions: () => {
      return authStore.authorization?.permissions
    },

    /**
     * fetch pending announcements that user has not yet acknowledged
     */
    fetchPendingAnnouncements: async () => {
      return await userApi.fetchPendingAnnouncements()
    },

    /**
     * marks announcements as acknowledged by current user by provided announcement `id`
     */
    acknowledgeAnnouncement: async (id: number) => {
      const response = await userApi.acknowledgeAnnouncement(id)

      NotifyByAPIResponse.execute(response)
    },
    authStore,
  };
};

export default useAuth;
