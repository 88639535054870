import { defineStore } from 'pinia';
import type { menuDataConfig, tabsDataConfig, AssetConfig, EmailTemplateState } from "~/packages/core/interfaces/global"
import gridData from '~/config/common/grid.json';
import GetMenuData from "~/packages/core/actions/config/GetMenuData";
import type AuthorsCollection from '~/packages/core/models/AuthorsCollection';
import type Model from "~/packages/core/models/Model";

export const useGlobalState = defineStore('globalState', {
  state: () => {
    return {
      // Main menu state
      isMenuOpen: false as boolean,
      //Menu Data
      Menus: GetMenuData.execute() as menuDataConfig[],

      //Email Template State
      emailTemplate: {
        selectedType: null
      } as EmailTemplateState,

      //Navigation Prompt Modal State
      showNavigationModal: false as boolean,

      //Author State
      authors: [] as AuthorsCollection[],
      
      //API Loading State
      isApiLoading: false as any,

      // column definations for data grid
      ArticleDefinations: gridData.assets as AssetConfig,
      RolesDefinitions: gridData.role,

      navigationAssetInfo: {
        model: {} as Model,
        listing_url: '' as string,
        edit_url: '' as string,
      },
    };
  },
  persist: {
    // @ts-ignore
    storage: persistedState.localStorage,
    paths: ['emailTemplate']
  },
  actions: {
  },
});



