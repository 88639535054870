/*
* useCampaignAssets extends defaultApi composable to use Api state and Common Api Notifications
 */

class useCampaignAssets extends useDefaultApi {
    protected api = 'campaign_assets'

    override create(model: any, payload: any, url?: string) {
        return super.create(model, payload, url, false);
    }
}


export default  useCampaignAssets;
