import Model from "~/packages/core/models/Model";

class CampaignUserModel extends Model {
  casts(): {} {
    return {
      id: "number",
      author_id: "number",
      name_first: "string",
      name_last: "string",
      address: "string",
      city: "string",
      state: "string",
      state_other: "string",
      country: "string",
      zip: "string",
      phone: "string",
      fax: "string",
      email: "string",
      website: "string",
      title: "string",
      date_acq: "string",
      last_login: "string",
      active: "string",
      username: "string",
      password: "string",
      epassword: "string",
    };
  }

  /**  GETTERS **/
    get fullName(): string {
        return this.name_first + " " + this.name_last
    }

    set fullName(value: string) {
        this.name_first = value.split(" ")[0]
        this.name_last = value.split(" ")[1]
    }
}

export default CampaignUserModel;
