const useAssetTypesConfig = () => {
  return {
    captions: {
      assetTypes: [
        { label: "Article", id: "article" },
        { label: "Interview", id: "interview" },
        { label: "Webinar", id: "webinar" },
      ],
    },
    cloneAssets: {
      assetTypes: [
        { label: "Article", id: "article" },
        { label: "Blog Post", id: "blogpost" },
        { label: "Interview", id: "interview" },
        { label: "White Paper", id: "whitepaper" },
        { label: "Webinar", id: "webinar" },
        { label: "Hand Book", id: "handbook" },
      ],
    },
    emailcenter: {
      assetTypes: [
        {
            label: "Article",
            id: "AR",
        },
        {
            label: "Blog Post",
            id: "BL",
        },
        {
            label: "Interview",
            id: "PD",
        },
        {
            label: "Press Release",
            id: "PR",
        },
        {
            label: "White Paper",
            id: "WP",
        },
        {
            label: "Webinar",
            id: "WB",
        },
      ],
    },
    campaigns : {
      assetTypes: [
        { label: "Article", id: "article" },
        { label: "Blog Post", id: "blogpost" },
        { label: "Interview", id: "podcast" },
        { label: "White Paper", id: "whitepaper" },
        { label: "Webinar", id: "webinar" },
        { label: "Hand Book", id: "handbook" },
      ],
    },
    popups : {
      assetTypes: [
        {label: "Custom", id: "custom"},
        { label: "Blog Post", id: "blogpost" },
        { label: "Interview", id: "interview" },
        { label: "White Paper", id: "whitepaper" },
        { label: "Webinar", id: "webinar" },
        { label: "Hand Book", id: "handbook" },
      ],
    },
  };
}

export default useAssetTypesConfig;