import Collection from '~/packages/core/models/Collection';
import FeaturedMenuItemModel from "~/packages/core/models/tools/FeaturedMenuItemModel";

class FeaturedMenusItemsCollection extends Collection {
    /**
     * Define associated model to the FeaturedMenusCollection
     */
    override model(): typeof FeaturedMenuItemModel {
        return FeaturedMenuItemModel;
    }

    /** Getters **/
}

export default FeaturedMenusItemsCollection;
