import Model from "~/packages/core/models/Model";
import {useGlobalState} from "~/store/useGlobalState";
import {type Payload} from "~/packages/core/types/Api";
import {type Groups} from "~/packages/core/interfaces/global";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";

//@ts-ignore
class GroupModel extends Model implements Groups {
    static override permission_name: string = ''

    override casts() {
        return {};
    }

    /**** GETTERS ****/
    /** Methods **/
    async list(type: string, payload?: Payload) {
        return await useApi().groupsApi.list(type, payload);
    }

    async create(type: string, model: any, relation: string) {
        useGlobalState().isApiLoading = true;
        try {
            const group = await useApi().groupsApi.create(type, model);
            if (group) {
                //@ts-ignore
                if (group.metadata.status === "200") {
                    NotifyByAPIResponse.execute(group)
                }
                // @ts-ignore
                model.id = group.id
                if (model) {
                    await useApi().groupsApi.handleRelation(type, model, relation)
                }
            }
        } catch (error) {
            // @ts-ignore
            useNotification().notify('error', "Something went wrong please try again!");
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }

    async update(type: string, model: any, relation: string) {
        useGlobalState().isApiLoading = true;
        try {
            const group = await useApi().groupsApi.update(type, model);
            if (group) {
                //@ts-ignore
                if (group.metadata.status === "200") {
                    NotifyByAPIResponse.execute(group)
                }
                if (model) {
                    await useApi().groupsApi.handleRelation(type, model, relation)
                }
            }
        } catch (error) {
            // @ts-ignore
            useNotification().notify('error', "Something went wrong please try again!");
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }

    async deleteGroup(type: string, model: any) {
        useGlobalState().isApiLoading = true;
        try {
            const group = await useApi().groupsApi.delete(type, model);
            if (group.meta.status === "200") {
                NotifyByAPIResponse.execute(group)
            }
            location.reload()
        } catch (error) {
            // @ts-ignore
            useNotification().notify('error', "Something went wrong please try again!");
        } finally {
            useGlobalState().isApiLoading = false
        }
    }

    scrollToNewGroup() {
        const groupElements = document.querySelectorAll('.cms-group-item');
        if (groupElements.length > 0) {
            const lastGroupElement = groupElements[groupElements.length - 1];
            lastGroupElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
}

export default GroupModel;
