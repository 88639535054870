import Collection from "~/packages/core/models/Collection";
import TitleLevelsModel from "~/packages/core/models/TitleLevelsModel";

class TitleLevelsCollection extends Collection {
    //define Title_levels model to return model
    model(): typeof TitleLevelsModel {
        return TitleLevelsModel
    }

    /**** GETTERS ****/
    //modifying the object that we get from response to show title_levels list in Multiselect
    get SelectOptions(): {} {
        return this.map((title_level: TitleLevelsModel) => {
            // @ts-ignore
            return { value: title_level.id, title: title_level.title, id: title_level.id }
        });
    }
}

export default TitleLevelsCollection