/*
* useCampaignHistory extends defaultApi composable to use Api state and Common Api Notifications
 */

class useCampaignHistory extends useDefaultApi {
    protected api = 'campaign_history'
    
    override create(model:any , payload: any, url?: string) {
        return super.create(model, payload, url, false);
    }
}


export default  useCampaignHistory;