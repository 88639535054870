import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import type { Router } from "vue-router";

/**
 * This class provides a method to navigate to the nearest valid parent route.
 */
export default class GoBack extends BaseStaticAction {
    static override execute(): void {
        const pathSegments = useNuxtApp().$NuxtRoute.path.split("/").filter(Boolean) as string[];
        const router = useNuxtApp().$NuxtRouter as Router;
        
        // Temporarily disable console warnings
        const originalConsoleWarn = console.warn;
        console.warn = () => {};

        // Start removing segments from the end until we find a valid route
        while (pathSegments.length > 0) {
            // Remove the last segment
            pathSegments.pop();
            
            // Construct the potential path
            const potentialPath = `/${pathSegments.join('/')}`;
            
            try {
                const matchedRoute = router.resolve(potentialPath);
                if (matchedRoute.matched.length > 0 && 
                    matchedRoute.matched.some(match => match.components?.default)) {
                    console.warn = originalConsoleWarn; // Restore console warnings
                    router.push(potentialPath || '/');
                    return;
                }
            } catch {
                // If route resolution fails, continue to the next iteration
                continue;
            }
        }

        // Restore console warnings before final navigation
        console.warn = originalConsoleWarn;
        router.push('/dashboard');
    }
}