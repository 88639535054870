import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import AuditsCollection from "~/packages/core/models/tools/AuditsCollection";
import AuditModel from "~/packages/core/models/tools/AuditModel";

class AuditApi extends DefaultApi {
    protected path:string = 'audit'
    protected collection:BaseCollection = AuditsCollection
    protected model:BaseModel = AuditModel
}


export default AuditApi