import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import AnnouncementsCollection from "~/packages/core/models/tools/AnnouncementsCollection";
import AnnouncementModel from "~/packages/core/models/tools/AnnouncementModel";
import {Payload} from "~/packages/core/types/Api";
import AnnouncementsStatisticsCollection from "~/packages/core/models/tools/AnnouncementsStatisticsCollection";

class AnnouncementsApi extends DefaultApi {
    protected path:string = 'announcements'
    protected collection:BaseCollection = AnnouncementsCollection
    protected model:BaseModel = AnnouncementModel

    async statisticsList (payload?:Payload, url?:any, cast:boolean = true){
        try{
            const request_url = this.getRequestUrl(this.path, 'statistics', { payload });
            const {data:response} = await this.makeRequest(request_url, this.GET);
            const entity_collection = AnnouncementsStatisticsCollection

            // @ts-ignore
            return cast ? new entity_collection(response.value) : response
        }
        catch (error){
            useLog().trace(error)
        }
    }
}


export default AnnouncementsApi