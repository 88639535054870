import FeaturedWebinarModel from '~/packages/core/models/FeaturedWebinarModel';
import Collection from '~/packages/core/models/Collection';

class FeaturedWebinarsCollection extends Collection {
    /**
     * Define associated model to the FeaturedWebinarsCollection
     */
    model(): typeof FeaturedWebinarModel {
        return FeaturedWebinarModel;
    }

    /** Methods **/
}

export default FeaturedWebinarsCollection;
