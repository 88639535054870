import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import useLog from "~/packages/core/composables/useLog";

/**
 * This class is a single action class that handles API responses
 * and notifies messages for success or error.
 */
export default class NotifyByAPIResponse extends BaseStaticAction {
    private static notification_options: any = {}

    static execute(response?: any, notification_options?: {}): void {
        this.notification_options = notification_options

        if (!response) {
            useLog().info([{ response }]);
            return;
        }

        const messages = response.meta?.response_details?.messages || response.metadata?.response_details?.messages || response.messages || {};

        // Handle success and error messages
        this.handleMessages(messages.success, 'success');
        this.handleMessages(messages.error, 'error');
        this.handleMessages(messages.warning, 'warning');
        this.handleMessages(messages.warning, 'info');
    }

    private static handleMessages(messages: any, type: string): void {
        if (!messages) return;

        // Check if messages are field-specific
        if (typeof messages === 'object' && !Array.isArray(messages)) {
          Object.keys(messages).forEach(field => {
            const fieldMessages = messages[field];
            if (Array.isArray(fieldMessages)) {
              this.notify(fieldMessages, type);
            }
          });
        }

        // Notify top-level messages if present
        if (Array.isArray(messages)) {
            this.notify(messages, type);
        }
    }

    private static notify(messages: string[], type: string) {
        messages.forEach((message: string) => {
            useNotification().notify(type, message, this.notification_options);
        });
    }
}