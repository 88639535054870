export default defineNuxtPlugin(() => {
    const NuxtRouter = useRouter();
    const NuxtRoute = useRoute();

    // 1. Add a local variable to store the last route's full path
    let previousUrl = ''

    // 2. Use a global router guard to update `previousUrl`
    NuxtRouter.beforeEach((to, from, next) => {
        // `from.fullPath` = the route the user is leaving
        previousUrl = from.fullPath
        next()
    })

    return {
        provide: {
            NuxtRouter,
            NuxtRoute,
            getPreviousUrl: () => previousUrl,
        },
    };
});