import Model from "~/packages/core/models/Model";

class AnnouncementStatisticsModel extends Model {
    override casts(): {} {
        return {
            uid: "number",
            announcement_id: "number",
            announcement_title: "string",
            user_id: "string",
            username: "string",
            fullname: "string",
            aknowledged_at: "string"
        };
    }

    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    getDefaultOptions(): ModelOptions {
        return {
            identifier: 'uid'
        }
    }
}

export default AnnouncementStatisticsModel;
