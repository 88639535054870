import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CampaignIndustryModel from "~/packages/core/models/clientservices/CampaignIndustry";
import CampaignIndustriesCollection from "~/packages/core/models/clientservices/CampaignIndustriesCollection";

class CampaignIndustriesApi extends DefaultApi {
    protected override path:string = 'campaign_industries'
    protected override collection:BaseCollection = CampaignIndustriesCollection
    protected override model:BaseModel = CampaignIndustryModel
}

export default CampaignIndustriesApi;