/*
* useCampaigns extends defaultApi composable to use Api state and Common Api Notifications
 */

import { type Payload } from "~/packages/core/types/Api";
import { useGlobalState } from "~/store/useGlobalState";
import type CampaignModel from "~/packages/core/models/clientservices/CampaignModel";
import GoBack from "~/packages/core/actions/utility/GoBack";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";

class useCampaigns extends useDefaultApi {
    protected api = 'campaigns'
    protected excluded = ['authors', 'industries', 'contacts']

    async searchCampaigns(query: string) {
        //@ts-ignore
        return await useApi()[this.api].searchCampaigns(query)
    }

    async create(model: CampaignModel, payload?: Payload, url?: string) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].create(
                model.getModelData(this.excluded), payload, url
            );
            if(entity){
                model.campaign_id = entity.campaign_id;
                if (model) {
                    //@ts-ignore
                    const [industries, contacts] = await Promise.all([
                        //@ts-ignore
                        useApi()[this.api].handleRelation('industries', model),
                        //@ts-ignore
                        useApi()[this.api].handleRelation('contacts', model)
                    ]);
                }

                if (entity.metadata.status === "200") {
                    // @ts-ignore
                    NotifyByAPIResponse.execute(entity)
                    GoBack.execute()
                }
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }


    async update(model: CampaignModel, payload?: Payload, url?: string) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].update(
                model.getModelData(this.excluded),
                model.id,
                payload,
                url
            );
            if (model) {
                const [industries, contacts] = await Promise.all([
                    //@ts-ignore
                    useApi()[this.api].handleRelation('industries', model),
                    //@ts-ignore
                    useApi()[this.api].handleRelation('contacts', model)
                ]);
            }
            if (entity.metadata.status === "200") {
                //@ts-ignore
                NotifyByAPIResponse.execute(entity)
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }
}


export default  useCampaigns;