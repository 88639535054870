import { merge } from "lodash";
import AssetModel from "~/packages/core/models/AssetModel";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import type { ModelOptions } from "~/packages/core/models/BaseModel";

class PressreleaseModel extends AssetModel {
  static fields: string[] = ["title", "id"];
  static override permission_name: string = "press_release";
  static override exempt_fields:string[] = [
    'active'
  ]
  override casts() {
    return merge(super.casts(), {
      id: "number",
      old_id: "number",
      authors: AuthorsCollection,
      listing_type: "string",
      company: "string",
      address: "string",
      address_2: "string",
      city: "string",
      country: "string",
      state: "string",
      state_other: "string",
      zipcode: "string",
      phone: "string",
      start_date: "string",
      title: "string",
      document: "string",
      active: "string",
      is_ismg_press: "number",
      vendor: "string",
      categories: CategoriesCollection,
    });
  }
  /**** GETTERS ****/

  /**
   * Getter example use case
   */
  override getDefaultOptions(): ModelOptions {
    return {
      identifier: "id",
    };
  }
  /**** GETTERS END ****/

  /**SETTERS */
}

export default PressreleaseModel;
