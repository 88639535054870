import { AgGridVue } from "@ag-grid-community/vue3";
import { LicenseManager } from '@ag-grid-enterprise/core';
import AgGridActionBar from "~/components/shared/blocks/AgGridActionBar.vue";
import ActionBar from "~/components/custom/content/assets/ActionBar.vue";
import ManageMembershipActionBar from "~/components/custom/users/membership/ManageActionBar.vue";
import TranscodeGridSizes from "~/components/custom/tools/videotranscoding/TranscodeGridSizes.vue";
import TranscodeGridTime from "~/components/custom/tools/videotranscoding/TranscodeGridTime.vue";
import HtmlRenderer from "~/components/shared/grid/renderers/HtmlRenderer.vue";
import BooleanCheckRenderer from "~/components/shared/grid/renderers/BooleanCheckRenderer.vue";
import BooleanCheckFilter from "~/components/shared/grid/filters/BooleanCheckFilter.vue";
import WebsitesFilter from "~/components/shared/grid/filters/WebsitesFilter.vue";
import EditableBooleanToggleRenderer from "~/components/shared/grid/renderers/EditableBooleanToggleRenderer.vue";
import UsersExtraActions from "~/components/shared/grid/renderers/Users/ExtraActions.vue";
import NewslettersExtraActions from "~/components/shared/grid/renderers/newsletters/ExtraActions.vue";
import SharedGridRenderersToolsAuditActions from "~/components/shared/grid/renderers/Tools/AuditActions.vue";
import ImportantFilesActions from "~/components/shared/grid/renderers/Campaigns/ImportantFilesActions.vue";
export default defineNuxtPlugin((nuxtApp) => {
  LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_API_KEY);
  nuxtApp.vueApp.component("AgGridVue", AgGridVue);
  nuxtApp.vueApp.component('AgGridActionBar', AgGridActionBar);
  nuxtApp.vueApp.component('ActionBar', ActionBar);
  nuxtApp.vueApp.component('ManageMembershipActionBar', ManageMembershipActionBar);
  nuxtApp.vueApp.component('TranscodeGridSizes', TranscodeGridSizes);
  nuxtApp.vueApp.component('TranscodeGridTime', TranscodeGridTime);
  nuxtApp.vueApp.component('HtmlRenderer', HtmlRenderer);
  nuxtApp.vueApp.component('BooleanCheckRenderer', BooleanCheckRenderer);
  nuxtApp.vueApp.component('BooleanCheckFilter', BooleanCheckFilter);
  nuxtApp.vueApp.component('EditableBooleanToggleRenderer', EditableBooleanToggleRenderer);
  nuxtApp.vueApp.component('WebsitesFilter', WebsitesFilter);
  nuxtApp.vueApp.component('UsersExtraActions', UsersExtraActions);
  nuxtApp.vueApp.component('ImportantFilesActions', ImportantFilesActions);
  nuxtApp.vueApp.component('SharedGridRenderersToolsAuditActions', SharedGridRenderersToolsAuditActions);
  nuxtApp.vueApp.component('NewslettersExtraActions', NewslettersExtraActions);
});
