import defaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CampaignFiltersCollection from "~/packages/core/models/marketing/CampaignFiltersCollection";
import CampaignFilterModel from "~/packages/core/models/marketing/CampaignFilterModel";

class CampaignFilterApi extends defaultApi {
    protected override path:string = 'campaign_filters'
    protected override collection:BaseCollection = CampaignFiltersCollection
    protected override model:BaseModel = CampaignFilterModel
    async searchCampaignFilters(query: string, payload?: {}, cast = true) {
        try {
            let url = `${this.path}/all?fields[]=id&fields[]=name&order_by=name&order_direction=asc&search_fields=name,id&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new CampaignFiltersCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }

    private async deleteByFilter(urlSuffix: string, data: {}): Promise<any> {
        try {
            const url = `${this.path}/${urlSuffix}`;
            const { data: response, error } = await this.makeRequest(url, this.POST, data);
            this.handleError(error);
            return response;
        } catch (error) {
            this.handleError(error);
            useLog().trace(error);
        }
    }

    async deleteExcludedLeads(filter_id: number): Promise<any> {
        return this.deleteByFilter('excluded_leads/delete', { filter_id });
    }

    async deletePreviousCompanies(filter: CampaignFilterModel): Promise<any> {
        return this.deleteByFilter('previous_companies/delete', { filter });
    }
}

export default CampaignFilterApi;
