import Model from "~/packages/core/models/Model";

class CampaignIndustryModel extends Model {
    override casts(): {} {
        return {
            id: "number",
            name: "string",
        };
    }
}

export default CampaignIndustryModel;
