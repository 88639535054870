import BaseApi from "~/packages/core/services/BaseApi";
import {type Payload} from "~/packages/core/types/Api";
import {ObjectToQuery} from "~/packages/core/utility/ObjectToQuery";
import useGroupsCollection from "~/packages/core/composables/useGroupsCollection";
import BaseModel from "~/packages/core/models/BaseModel";

class GroupsApi extends BaseApi {
    //List api to fetch featuredevents events
    async list (type:string, payload?:Payload, cast:boolean = true) {
        try{
            let url_to_query_string = payload ? '?' + ObjectToQuery(payload) : '';
            let url = type + url_to_query_string + '&order_by=id&order_direction=asc';

            const {data:response} =  await this.makeRequest(url, this.GET);
            const GroupsCollection = useGroupsCollection(type).collection
            return cast ? new GroupsCollection(response.value) : response
        }
        catch(error) {
            useLog().trace()
        }
    }

    //create featuredevents events
    async create (type:string, model:any, cast:boolean = true){
        try{
            let url:string = type

            const { data:response } = await this.makeRequest(url, this.POST, model.getModelData(['events', 'webinars']));
            const GroupsModel = useGroupsModel(type).model
            return cast ? new GroupsModel(response.value) : response
        }
        catch(error){
            useLog().trace()
        }
    }

    async update (type:string, model:BaseModel, cast:boolean = true){
        try{
            // @ts-ignore
            let url:string = type + `/${model.id}`

            const { data:response } = await this.makeRequest(url, this.PUT, model.getModelData(['events', 'webinars']));
            const GroupsModel = useGroupsModel(type).model
            return cast ? new GroupsModel(response.value) : response
        }
        catch(error){
            useLog().trace()
        }
    }

    //delete featuredevents event
    async delete (type:string, model:any) {
        try{
            //@ts-ignore
            let url:string = `${type}/${model.id}`;
            const { data:response } = await this.makeRequest(url, this.DELETE);
            return response.value
        }
        catch(error){
            useLog().trace()
        }
    }

    //handle events relation
    async handleRelation (type:string, model:any, relation:string) {
        try{
            //@ts-ignore
            let url:string = `${type}/${model.id}/${relation}`
            const { data:response } = await this.makeRequest(url, this.POST, model.getModelData())
            return response.value
        }
        catch(error){
            useLog().trace()
        }
    }
}

export default GroupsApi