/*
* useCampaignFilters extends defaultApi composable to use Api state and Common Api Notifications
 */

import {useGlobalState} from "~/store/useGlobalState";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";
import type CampaignFilterModel from "~/packages/core/models/marketing/CampaignFilterModel";

class useCampaignFilters extends useDefaultApi {
    protected override api = 'campaign_filters'
    protected override excluded: string[] = ['file_details', 'field_name'];

    async searchCampaignFilters(query: string) {
        // @ts-ignore
        return await useApi()[this.api].searchCampaignFilters(query)
    }

    async deleteExcludedLeads(filter_id: number) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const response = await useApi()[this.api].deleteExcludedLeads(filter_id)

            NotifyByAPIResponse.execute(response.value)
        }
        catch (error){
            // @ts-ignore
            useNotification().notify('error',"Something went wrong please try again!");
        }
        finally {
            useGlobalState().isApiLoading = false;
        }
    }

    async deletePreviousCompanies(filter: CampaignFilterModel) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const response = await useApi()[this.api].deletePreviousCompanies(filter)

            NotifyByAPIResponse.execute(response.value)
        }
        catch (error){
            // @ts-ignore
            useNotification().notify('error',"Something went wrong please try again!");
        }
        finally {
            useGlobalState().isApiLoading = false;
        }
    }
}


export default  useCampaignFilters;